import React from 'react'
import {  
    Box,
    Button,
    Stack,
    TextField
} from '@mui/material'
import Title from './Title'
import Paragraph from './Paragraph'

const Details = () => {
    

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            phone: data.get('phone'),
        });
    }


    return (
        <Stack 
        component='section'
        direction="column"
        justifyContent= 'center'
        alignItems='center'
        sx={{
            py: 10,
            px: 2,
        }}
        >
            <Title 
            text={
                'Время работы:'
                } 
            textAlign={'center'}
            />
            <Paragraph 
            text={
                'Понедельник - пятница с 11:00 до 17:00. Телефон для записи:'
            }
            maxWidth = {'sm'}
            mx={0}
            textAlign={'center'}
            />
            {<div><a href="tel:+79095646466">+79095646466</a></div>}
            <Paragraph 
  
            />
            <Paragraph 
  
            />
            <Paragraph 
  
            />
            <Paragraph 
  
            />
            <Paragraph 
  
            />
            <Paragraph 
  
            />
            <Paragraph 
  
            />
            {/* <Box 
            component="form" 
            noValidate 
            onSubmit={handleSubmit} 
            sx={{ 
                mt: 1,
                py: 2
            }}>
                
            </Box> */}
        </Stack>
    )
}

export default Details;